<template>
  <v-list width="100%" class="py-0 mr-sm-16 mb-n2">
    <v-list-item-group
      v-model="selectedTag"
      @change="setTags"
      class="d-flex flex-wrap"
    >
      <v-list-item
        v-for="tag in activeTags"
        :key="`item-${tag.id}`"
        :value="tag.id"
        :ripple="false"
        active-class="is-selected"
        class="tag-item d-flex flex-grow-0 flex-basis-auto mr-2 mb-2 px-3 rounded"
      >
        <v-list-item-content class="pa-0">
          <v-list-item-title class="d-flex align-center text-captions-2 text-wrap text-break" :title="tag.title">
            {{ tag.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :inactive="true" :ripple="false"
                   class="tag-item tag-plus-btn flex-grow-0 flex-basis-auto mr-2 mb-2 px-0">
        <v-list-item-content class="pa-0 flex-grow-0 flex-basis-auto">
          <UiMenu
            v-model="menu"
            :close-on-content-click="false"
            right
            allow-overflow
            offset-y
            offset-x
            max-width="182"
            min-width="182"
            :nudge-bottom="$vuetify.breakpoint.xsOnly ? 0 : 10"
            :nudge-left="$vuetify.breakpoint.xsOnly ? 0 : 30"
            content-class="tag-list-menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <UiBtn
                v-bind="attrs"
                v-on="on"
                outlined
                fab
                color="accent"
                class="tag-list-menu-btn flex-grow-0 flex-basis-auto mb-0"
              >
                <IconPlus width="16"/>
              </UiBtn>
            </template>

            <div class="pt-2 pb-3 px-1 gray-10">

              <div v-if="loading" class="d-flex justify-center py-16">
                <v-progress-circular
                  :size="50"
                  color="accent"
                  indeterminate
                />
              </div>

              <template v-else>
                <v-list class="pa-0" color="transparent" nav dense>
                  <v-list-item-group
                    v-model="selectedTags"
                    :multiple="$vuetify.breakpoint.smAndUp"
                  >
                    <template v-for="(tag, idx) in tags">
                      <v-hover v-slot="{hover}" :key="`item-${idx}`">
                        <v-list-item
                          :value="tag.id"
                          class="mb-1 px-0"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-action class="pl-2 my-0 mr-2">
                              <UiCheckbox
                                checkbox-form="square"
                                :input-value="active"
                                :hovered="hover"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title :title="tag.title">
                                <span class="mr-2 text-captions-1 overflow-hidden text-overflow-ellipsis">{{
                                    tag.title
                                  }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </v-hover>
                    </template>
                  </v-list-item-group>
                </v-list>
              </template>
            </div>
          </UiMenu>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import UiCheckbox from "@/components/UI/UiCheckbox";

export default {
  name: 'Tags',
  components: {
    IconPlus: () => import('@/components/icons/IconPlus'),
    UiMenu,
    UiBtn,
    UiCheckbox,
  },
  props: {
    tags: {
      type: Array,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
      menu: false,
      selectedTag: this.$store.getters.getProjectDesignsFilters.tags[0],
      selectedTagsList: this.$store.getters.getProjectDesignsFilters.tagsList,
      newTag: '',
      copyTagError: false,
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getProjectDesignsFilters',
      'getPermission',
    ]),
    activeTags() {
      return this.tags.filter(tag => this.selectedTagsList.includes(tag.id));
    },
    selectedTags: {
      get() {
        return this.selectedTagsList;
      },
      set(nv) {
        this.selectedTagsList = nv;
        this.setTagsList();
      }
    }
  },
  methods: {
    ...mapActions([
      'getProjectsTagsList'
    ]),
    async setFilter() {
      this.menu = false;
      await this.$router.$updateQueryParams(this.getProjectDesignsFilters);

      if (this.getProjectDesignsFilters.tags.includes(undefined)) return

      this.$emit('setFilter', null);
    },
    setTags() {
      this.$store.dispatch('setProjectDesignsFilters', {tags: this.selectedTag ? [this.selectedTag] : []});
      this.saveToLocalStorage()
      this.setFilter();
    },
    setTagsList() {
      this.$store.dispatch('setProjectDesignsFilters', {tagsList: [this.selectedTagsList]});
      this.saveToLocalStorage()
      this.$router.$updateQueryParams(this.getProjectDesignsFilters);
    },
    async saveToLocalStorage() {
      const tagsStorage = await this.getProjectsTagsList()
      localStorage.setItem('tagsList', JSON.stringify({
        ...tagsStorage,
        [this.$route.params.project_id]: {
          tagsList: [this.selectedTagsList].toString(),
          tags: this.selectedTag ? [this.selectedTag].toString() : ''
        }
      }))
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .tag-item {
  min-height: 28px;

  &::before {
    display: none;
  }
}

::v-deep .tag-item:not(.tag-plus-btn) {
  border: 1px solid var(--v-accent-base);
  background-color: var(--v-accent-10-base);

  .v-list-item__title {
    color: var(--v-accent-base);
  }

  &.is-selected {
    background-color: var(--v-accent-base);

    .v-list-item__title {
      color: var(--v-gray-0-base);
    }
  }
}

.tag-list-menu-btn {
  background-color: var(--v-accent-10-base);
}
</style>

<style lang="scss">
.ui-menu-content.tag-list-menu {
  border: 1px solid var(--v-gray-30-base) !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25) !important;
}
</style>
