<template>
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
      flat
      :ripple="false"
      :class="['ui-list-card', {'is-selected': selected}]"
      @click="select">
      <div class="ui-list-card__body d-flex align-center relative">
        <div class="relative flex-shrink-0">
          <div class="ui-list-card__badges absolute space-x-2 d-flex align-end" v-if="$slots.badges">
            <slot name="badges"/>
          </div>
          <div class="ui-list-card__image d-flex flex-column relative overflow-hidden" :class="{'pb-0': !itemStatus}">
            <UiSelectedIndicator
              v-if="selectable"
              :selected="selected"
              class="absolute mt-2 ml-2"/>

            <slot name="image"/>

            <UiProjectStatus
              v-if="itemStatus !== null"
              :id="itemStatus"
              block
              class="ui-list-card__status absolute"
            />
          </div>
        </div>

        <div class="ui-list-card__content fill-width">
          <slot name="body"/>
        </div>
      </div>

      <div v-if="$slots.footer" class="ui-list-card__footer">
        <slot name="footer"/>
      </div>
    </v-card>
</template>

<script>
import UiProjectStatus from "@/components/UI/UiProjectStatus";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";

export default {
  name: 'UiListCard',
  components: {
    UiSelectedIndicator,
    UiProjectStatus
  },
  props: {
    item: {
      type: Object,
      default: () => {
      },
      required: true,
    },
    itemStatus: {
      type: [Number, String],
      default: null
    },
    size: {
      type: Object,
      default: () => {
      },
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    select() {
      if (!this.$props.selectable) return;

      this.$emit('onSelect', !this.selected);
    }
  }
}
</script>

<style scoped lang="scss">
.ui-list-card {
  border: 1px solid var(--v-gray-10-base);
  border-radius: 2px;
  background-color: var(--v-gray-0-base);
  cursor: pointer;

  &.medium {
    min-height: 90px;
    .ui-list-card__image {
      height: 90px;
    }
  }

  &.small {
    min-height: 60px;
    .ui-list-card__image {
      height: 60px;
    }
  }

  &:hover:not(.is-selected) {
    border-color: var(--v-gray-60-base);
    background-color: var(--v-gray-10-base);
  }

  &.is-selected {
    border-color: var(--v-accent-base);
  }

  &__body {
    height: 100%;
  }

  &__content {
    max-width: calc(100% - 148px);
  }

  &__badges{
    top: -10px;
    //right: -10px;
    left: 60px;
    z-index: 1;
  }

  &__image {
    background-color: var(--v-gray-30-base);
    width: 148px;
    padding-bottom: 22px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__status {
    left: 0;
    right: 0;
    bottom: 0;
  }

}
</style>
