<template>
  <UIFilesUploader
    v-if="simpleView"
    :files-formats="$config.project.modulesFileFormats[getActiveDesignModule.slug]"
    :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize"
    :disabled="!abilityToUpload"
    multiple
    class="dropzone flex-column align-center justify-center overflow-hidden cursor-pointer"
    :class="[simpleView && abilityToUpload? 'simple-dropzone d-flex' : 'd-none']"
    @onChange="createDesign"
  >
    <template v-if="abilityToUpload">
      <div class="dropzone-footer d-flex align-center py-8 gray-60--text">
        <IconUpload width="16" class="gray-60--text mr-2"/>
        <span class="text-decoration-underline font-weight-light">Upload New Style</span>
      </div>
    </template>
    <template v-else>
      <b class="mb-2 text-uppercase text-center font-weight-bold">
        You don't have permissions to create style into {{ getActiveDesignModule.title }} module
      </b>
    </template>
  </UIFilesUploader>
  <v-responsive :aspect-ratio="878/652" v-else>
    <UIFilesUploader
      :files-formats="$config.project.modulesFileFormats[getActiveDesignModule.slug]"
      :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize"
      :disabled="!abilityToUpload"
      multiple
      class="dropzone d-flex flex-column align-center justify-center overflow-hidden absolute cursor-pointer"
      @onChange="createDesign"
    >
      <template v-if="abilityToUpload">
        <IconUpload width="48" class="mb-7 accent--text"/>
        <div class="dropzone-footer d-flex flex-column align-center">
          <b class="mb-2 text-uppercase font-weight-bold">Upload your styles</b>
          <span class="mb-0">Drag & Dropping multiple files will create a style for each file</span>
        </div>
      </template>
      <template v-else>
        <b class="mb-2 text-uppercase text-center font-weight-bold">
          You don't have permissions to create style into {{ getActiveDesignModule.title }} module
        </b>
      </template>
    </UIFilesUploader>
  </v-responsive>
</template>

<script>
import {mapGetters} from "vuex";
import UIFilesUploader from "@/components/UI/UIFilesUploader";

export default {
  name: 'UploadProjectDesign',
  props: {
    simpleView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconUpload: () => import('@/components/icons/IconUpload'),

    UIFilesUploader,
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getProjectDesignsFilters',
      'getActiveDesignModule',
      'getProjectPermissionData',
      'getPermission',
    ]),
    abilityToUpload() {
      const project_id = this.$route.params.project_id;
      return this.getPermission(project_id).design['can-create-style-design'] &&
        this.getProjectPermissionData(project_id).availableModules.includes(this.getActiveDesignModule.slug);
    }
  },
  methods: {
    createDesign(files) {
      this.$emit('createDesign', files);
    },
  }
}
</script>

<style scoped lang="scss">
.dropzone {
  border: 1px dashed var(--v-gray-30-base);
  border-radius: 2px;
  z-index: 2;

  &:not(.simple-dropzone){
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border-width: 2px;
  }

  b {
    letter-spacing: 0.2em;
  }

  b,
  span {
    font-size: 14px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .dropzone-footer {
      position: absolute;
      bottom: 54px;
    }
  }
}
</style>
