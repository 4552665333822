<template>
  <v-footer tag="div" app height="70" class="page-footer" color="gray-10" inset >
    <v-container class="py-0">
      <slot/>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "PageFooter"
}
</script>

<style scoped lang="scss">
.page-footer {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25) !important;
}
</style>
