<template>
  <div>
    <UiMenu
      offset-y
      allow-overflow
      left
      min-width="135"
      :nudge-bottom="$vuetify.breakpoint.xsOnly ? 0 : 10"
      :nudge-right="$vuetify.breakpoint.xsOnly ? 0 : 50"
      v-model="menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <UiBtn
          :color="isActive || menu ? 'accent' : 'gray-60'"
          icon
          width="auto"
          class="px-2"
          :plain="isActive || menu"
          @click="selectType"
          v-bind="attrs"
          v-on="isActive ? on : ''"
        >
          <IconThumbnail v-if="list[0].type === 'thumbnail'" width="15"/>
          <IconList v-else width="15"/>
          <IconCaretDown v-if="isActive" width="6" :class="['ml-2 icon-transition', {'rotate-180': menu}]"/>
        </UiBtn>
      </template>

      <v-list nav dense color="gray-10">
        <v-list-item-group
          v-model="selected"
          @change="selectItem"
        >
          <v-hover v-slot="{hover}" v-for="(item, i) in list" :key="`item-${i}`">
            <v-list-item
              :value="item"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action class="ma-0">
                  <UiCheckbox
                    :input-value="active"
                    color="accent-100"
                    :hovered="hover"
                    checkbox-form="radio"
                  />
                </v-list-item-action>
                <v-list-item-content class="py-1">
                  <v-list-item-title v-text="item.name"/>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-hover>
        </v-list-item-group>
      </v-list>
    </UiMenu>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import UiCheckbox from "@/components/UI/UiCheckbox";

export default {
  components: {
    IconThumbnail: () => import('@/components/icons/IconThumbnail'),
    IconList: () => import('@/components/icons/IconList'),
    IconCaretDown: () => import('@/components/icons/IconCaretDown'),

    UiCheckbox,
    UiMenu,
    UiBtn
  },
  props: {
    list: {
      type: Array,
      // example data
      default: () => [
        {
          slug: "large",
          name: "Large",
          type: "thumbnail" // thumbnail|list
        }
      ]
    },
    // isActive: {
    //   type: Boolean,
    //   default: false
    // },
    active: {
      type: [Object, Boolean],
      default: false
    }
  },
  watch: {
    active(val) {
      if(!val) {
        this.selected = this.list[0]
      }
    }
  },
  data() {
    return {
      menu: false,
      selected: this.active || this.list[0],
    }
  },
  computed: {
    isActive() {
      return Boolean(this.active)
    }
  },
  methods: {
    selectItem(v) {
      this.$emit('setGridView', v)
    },
    selectType() {
      this.$emit('setGridView', this.selected)
    }
  }
}
</script>
