<template>
  <div class="d-flex align-center">
    <v-text-field
      v-model="searchVal"
      prepend-inner-icon=""
      solo
      dense
      hide-details
      @keyup.enter="search"
      @click:prepend-inner="search"
      class="s-search"
    >
      <template #prepend-inner>
        <IconSearch width="12" class="gray-60--text ml-2"/>
      </template>
    </v-text-field>
  </div>
</template>

<script>

export default {
  name: 'UISearch',
  components: {
    IconSearch: () => import('@/components/icons/IconSearch'),
  },
  data() {
    return {
      searchVal: '',
    }
  },
  created() {
    this.searchVal = this.$route.query.search || '';
  },
  methods: {
    search() {
      this.$emit('onSearch', this.searchVal);
    },
  },
}
</script>

<style scoped lang="scss">
.s-search {
  height: 27px;
  &::v-deep {
    .v-input__slot {
      padding-left: 0 !important;
      min-height: 28px;
      box-shadow: none !important;
      border: 1px solid var(--v-gray-60-base);
      font-size: 12px;
      input {
        padding-top: 2px;
        caret-color: var(--v-gray-60-base) !important;
      }
    }
    .v-input__icon {
      button {
        font-size: 12px;
        color: var(--v-gray-60-base) !important;
      }
    }
  }
}
</style>
