<template>
  <UIDatePicker v-model="dueDate"
                :disabled="!getPermission($route.params.project_id).design['can-change-due-date-design']"
                :disabled-date="disabledBeforeToday" @save="save"/>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import UIDatePicker from "@/components/UI/UIDatePicker";
import {format} from "date-fns";

export default {
  name: 'DesignModuleDueDate',
  components: {
    UIDatePicker,
  },
  data() {
    return {
      dueDate: new Date().getTime(),
    }
  },
  computed: {
    ...mapGetters([
      'getActiveDesignModule',
      'getPermission',
    ]),
  },
  mounted() {
    this.dueDate = new Date().getTime() + this.getActiveDesignModule.ttl * 24 * 60 * 60 * 1000;
  },
  watch: {
    getActiveDesignModule: {
      handler(nv) {
        this.dueDate = new Date().getTime() + nv.ttl * 24 * 60 * 60 * 1000;
      }
    }
  },
  methods: {
    ...mapActions([
      'setActiveDesignModule',
      'setDesignModules'
    ]),
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    save(event) {
      const moduleId = this.getActiveDesignModule.id
      this.$api.projectModuleDesign.updateDueDate(this.$route.params.project_id, moduleId, {due_date: format(event.value, 'yyyy-MM-dd')})
        .then((res) => {
          const activeModule = res.data.find(module => module.id === moduleId);
          this.setDesignModules(res.data)
          this.setActiveDesignModule(activeModule);
        })
        .catch((error) => {
          console.error(error)
        })
    },
  }
}
</script>
