<template>
  <div class="side-drawer-controls">
    <UiBtn tile color="primary" min-width="40" width="40" height="40" @click="openSideDrawer('activities')">
      <IconActivity width="20px"/>
    </UiBtn>
    <UiBtn tile color="primary" min-width="40" width="40" height="40" @click="openSideDrawer('tasks')">
      <IconFilePlus width="20px"/>
    </UiBtn>
    <UiBtn tile color="primary" min-width="40" width="40" height="40" @click="openSideDrawer('files')">
      <IconPenTool width="20px"/>
    </UiBtn>
    <UiBtn tile color="primary" min-width="40" width="40" height="40" @click="openSideDrawer('logs')">
      <IconClockRewind width="20px"/>
    </UiBtn>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn.vue";
import {mapActions} from "vuex";

export default {
  name: 'SideDrawerControls',
  components: {
    IconActivity: () => import('@/components/icons/IconActivity'),
    IconPenTool: () => import('@/components/icons/IconPenTool'),
    IconFilePlus: () => import('@/components/icons/IconFilePlus'),
    IconClockRewind: () => import('@/components/icons/IconClockRewind'),
    UiBtn
  },
  methods: {
    ...mapActions(['toggleProjectSideDrawer', 'setActiveTab']),
    openSideDrawer(tab) {
      this.setActiveTab(tab)
      this.toggleProjectSideDrawer(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.side-drawer-controls {
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px 0 0 8px;

  :deep(.v-btn) {
    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
    }
  }
}
</style>
