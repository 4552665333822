<template>
  <v-hover v-slot="{ hover }">
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
      flat
      :class="['card relative', {'selected' : selected}]"
      @click="select"
      :ripple="false"
    >
      <div class="card__header absolute fill-width">
        <slot name="header" v-bind:hover="hover"/>
      </div>

      <div class="relative">
        <div class="card__badges absolute space-x-2 d-flex align-start" v-if="$slots.badges">
          <slot name="badges"/>
        </div>
        <v-responsive :aspect-ratio="16/10.2">
          <div class="card__image fill-height absolute fill-width inset-0 d-flex">
            <slot name="image" v-bind:hover="hover"/>

            <div class="card__image__status absolute fill-width">
              <slot name="status" v-bind:hover="hover"/>
              <UiProjectStatus
                v-if="!$slots.status && itemStatus !== null"
                :id="itemStatus"
                block
              />
            </div>
          </div>
        </v-responsive>
      </div>


      <div class="card__bottom pa-2">
        <slot name="bottom"/>
      </div>

      <v-fade-transition v-if="$slots.hover">
        <div v-show="hover && !selected" class="card__hover justify-center align-end absolute fill-width fill-height px-2">
          <slot name="hover"/>
        </div>
      </v-fade-transition>
    </v-card>
  </v-hover>
</template>

<script>
import UiProjectStatus from '@/components/UI/UiProjectStatus'

export default {
  name: "UiGridCard",
  components: {
    UiProjectStatus
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    itemStatus: {
      type: [Number, String],
      default: null
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    select() {
      if (!this.selectable) return;
      this.$emit('onSelect', !this.selected);
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid var(--v-gray-10-base);
  border-radius: 2px;
  cursor: pointer;

  &.selected {
    border-color: var(--v-accent-60-base);
  }

  &__header {
    z-index: 1;
    top: 0;
  }

  &__image {
    &__status {
      bottom: 0;
    }
  }

  &__badges {
    top: -10px;
    left: -10px;
    z-index: 1;
  }

  &__hover {
    top: 0;
    left: 0;
    z-index: 0;

    display: flex;

    &::before {
      content: '';
      background: var(--v-gray-60-base);
      opacity: 0.75;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 2px;
      z-index: -1;
    }
  }
}
</style>
