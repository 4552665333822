export default {
  methods: {
    async completeProject(projectIds, callback) {
      this.$store.dispatch("openModal", {
        modalName: "confirmModal",
        data: {
          title: "Mark as Completed",
          confirmBtnText: "Complete Project",
          confirmBtnIcon: '',
          confirmBtnColor: 'accent',
          cancelBtnColor: 'gray-60',
          showIconCross: true,
          actionBtnsPosition: 'right',
          modalMaxWidth: '416',
          descriptionAlign: 'left',
          descriptionIcon: 'IconInfoFilled',
          descriptionQuoteColor: 'accent-10',
          descriptionColor: 'gray-100--text',
          loading: true,
          loadingContent: true
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch("closeModal", "confirmModal");
          },
          onConfirm: async () => {
            const res = await this.completeProjectConfirm(projectIds)
            if(callback) {
              await callback(res);
            }
          },
        },
      });

      try {
        const {data} = await this.$api.project.getProjectsEmptyModules(projectIds)

        const {description, modulesWithoutAssetsText} = this.createCompleteProjectDescription(data)

        this.$store.dispatch('updateModalData', {
          modalName: 'confirmModal',
          data: {
            description: description,
            loading: false,
            loadingContent: false,
            descriptionContainerClasses: modulesWithoutAssetsText ? 'align-start' : 'align-center',
          }
        })
      } catch(error) {
        console.error(error)
        this.$store.dispatch('updateModalData', {
          modalName: 'confirmModal',
          data: {
            loading: false,
            loadingContent: false,
          }
        })
      }
    },
    createCompleteProjectDescription(data) {
      const modulesWithoutAssetsText = data.reduce((acc, el) => {
        if(el.modules.length === 1 && el.modules[0].type === this.$config.project.moduleTypes.moodBoard) {
          return acc
        }
        acc = acc + `<strong>${el.title}</strong>`
        const modulesList = el.modules.reduce((moduleAcc, moduleEl) => {
          if(moduleEl.type === this.$config.project.moduleTypes.moodBoard) {
            return moduleAcc
          }
          moduleAcc = moduleAcc + `<li>${moduleEl.title}</li>`
          return moduleAcc
        }, '')
        if(modulesList) {
          acc = acc + `<ul>${modulesList}</ul>`
        }
        return acc
      }, '')
      let description = '<p style="margin: 0;">This action will mark the project as completed and remove the current due dates from any open task.</p>'
      if(modulesWithoutAssetsText) {
        description = description + '<br><p>Modules that have no assets:</p>' + modulesWithoutAssetsText
      }
      return {
        description,
        modulesWithoutAssetsText,
      }
    },
    async completeProjectConfirm(projectIds) {
      try {
        this.$store.dispatch('updateModalData', {
          modalName: 'confirmModal',
          data: {
            loading: true,
          }
        })
        const res = await this.$api.project.setProjectsComplete(projectIds)
        this.$toast.open({
          message: 'Project marked as completed',
          type: 'success',
          position: 'top-right'
        });
        await this.$store.dispatch("closeModal", "confirmModal");
        return res
      } catch(error) {
        console.error(error)
        this.$store.dispatch('updateModalData', {
          modalName: 'confirmModal',
          data: {
            loading: false,
          }
        })
      }
    }
  },
}