<template>
  <UiMenu
    bottom
    right
    offset-y
    v-model="projectSettingsMenu"
    :nudge-bottom="2"
    :min-width="156"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn
        v-bind="attrs"
        v-on="on"
        @click.prevent
        class="p-0 ml-2"
        height="20"
        width="20"
        icon
        color="gray-60"

      >
        <IconSettings width="15" v-show="showBtn || projectSettingsMenu" />
      </UiBtn>
    </template>
    <v-list nav dense color="gray-10">
      <v-list-item v-for="menuItem in menuItems" :key="menuItem.title" :disabled="menuItem.disabled" @click="menuItem.action">
        <component :is="menuItem.icon" width="18" class="mr-2 gray-60--text"/>
        <v-list-item-title class="text-captions-1"
          >{{menuItem.title}}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
  export default {
    components: {
      UiMenu,
      UiBtn,
      IconSettings: () => import('@/components/icons/IconSettings'),
      IconCheck: () => import("@/components/icons/IconCheck"),
      IconDraw: () => import('@/components/icons/IconDraw'),
    },
    props: {
      showBtn: {
        type: Boolean,
        default: false
      },
      menuItems: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        projectSettingsMenu: false,
      }
    }
  }
</script>
