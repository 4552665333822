var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"calc(100% - 42px)"}},[_c('v-list-item-group',{staticClass:"d-flex fill-width overflow-y-auto mt-n4 mx-n4 mb-n1 px-4 pt-4",attrs:{"mandatory":""},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},_vm._l((_vm.currentModules),function(module){return _c('v-list-item',{key:module.id,class:['tab-btn flex fill-width text-captions-1 overflow-hidden mb-1', {
          'open-workflow': _vm.getProject.open_workflow,
          'completed': !_vm.getProject.open_workflow && module.status === _vm.$config.project.moduleStatus.finished,
          'tab-btn--active-small': _vm.currentModules.length >= 6
        }],attrs:{"value":module.id,"ripple":false,"disabled":module.disabled,"active-class":"tab-btn--active"}},[_c('UiProjectModuleIcon',{staticClass:"module-icon mr-2 flex-shrink-0",attrs:{"width":"14","icon":module.icon}}),(_vm.editModuleModal && _vm.editedModule.id === module.id)?_c('UiChangeNameInput',{attrs:{"placeholder":"Enter name","rules":'required|min:3|max:128'},on:{"onSave":_vm.updateModule,"onClose":function($event){_vm.editModuleModal = false}},model:{value:(_vm.editedModule.title),callback:function ($$v) {_vm.$set(_vm.editedModule, "title", $$v)},expression:"editedModule.title"}}):[_c('span',{staticClass:"text-no-wrap text-overflow-ellipsis overflow-hidden gray-60--text",class:{
              'mr-2': _vm.currentModules.length >=6 && module.id === _vm.internalValue
            }},[_vm._v(" "+_vm._s(module.title)+" ")])],(_vm.showActionMenu(module))?_c('UiMenu',{attrs:{"bottom":"","left":"","offset-y":"","nudge-bottom":10,"min-width":146,"z-index":10},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('UiBtn',_vm._g(_vm._b({staticClass:"ml-auto mr-n2",attrs:{"icon":"","width":"24","height":"24","color":"gray-60"}},'UiBtn',attrs,false),on),[_c('IconDotsV',{attrs:{"width":"16"}})],1)]}}],null,true)},[_c('v-list',{attrs:{"nav":"","dense":"","color":"gray-10"}},[(_vm.getPermission(_vm.$route.params.project_id).design['can-edit-module-name'])?_c('div',{staticClass:"v-list-item cursor-pointer",on:{"click":function($event){return _vm.openModuleModal(module)}}},[_c('div',{staticClass:"d-flex align-center text-captions-1"},[_c('IconPen',{staticClass:"mr-2 gray-60--text",attrs:{"width":"15"}}),_vm._v(" Change Name ")],1)]):_vm._e(),(_vm.showEditAccessButton)?_c('div',{class:['v-list-item', 'cursor-pointer'],on:{"click":function($event){return _vm.openEditAccessModal(module)}}},[_c('div',{staticClass:"d-flex align-center text-captions-1"},[_c('IconMember',{staticClass:"mr-2 gray-60--text",attrs:{"width":"15"}}),_vm._v(" Edit Access ")],1)]):_vm._e(),_c('div',{class:['v-list-item', 'cursor-pointer'],on:{"click":function($event){return _vm.downloadSummary(module)}}},[_c('div',{staticClass:"d-flex align-center text-captions-1"},[_c('IconDownload',{staticClass:"mr-2 gray-60--text",attrs:{"width":"15"}}),_vm._v(" Download Summary ")],1)])])],1):_vm._e()],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }